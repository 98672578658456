
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: black;
    font-size: 1rem;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

p , i{
    margin-top: 20px;
    text-align: justify;
    /* text-justify: inter-word; */
}




 .ant-layout-header,
.ant-layout-footer {
    color: #fff;
    background: #040F3D;
}

h6:hover {
    font-size: "1rem";
}

h2 {
    font-size: "1.25rem";
}
