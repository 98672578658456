
.ant-layout-header {
    height: 60px;
        line-height: 60px;
}

.ant-layout-header .ant-menu {
    line-height: 60px;
    background-color: #fff;
}
.main-header {
    background-color: #fff;
    /* cursor: none; */
}

.ant-menu-dark.ant-menu-horizontal >.ant-menu-item:hover{
    background-color: red;
    line-height: 26px;
}

.page-select:hover {
        background-color: red; 
        height: 10;
        padding: 22px;
        box-sizing: border-box;
        margin: 2px;
}

.logo{
    height: 56px;
    float: left;
}

.right-nav-item{
    float: right;
    margin: 0px;
    padding: 0px;
    width: 70px;
}