.layout {
    min-height: 100vh;
}

.header {
    background: #1890ff;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* .container {
    width: 80%;
    max-width: 700px;
    min-width: 320px;
} */
/* 
img {
    width: 80%;
    height: auto;
    max-width: 80%;
    min-width: 50%;
} */

@media screen and (max-width: 768px) {
    img {
        min-width: 100%;
    }
}
